import { Config as ConfigType } from './types';
import { user } from './cnrs/user';
import { expert } from './cnrs/expert';
import { client } from './cnrs/client';
import { company } from './cnrs/company';
import { mission } from './cnrs/mission';
import { invitation } from './cnrs/invitation';
import { candidate } from './s30/candidate';
import { candidacy } from './cnrs/candidacy';
import { recommendation } from './cnrs/recommendation';
import { legalInformation } from './cnrs/legalInformation';
import { cgu } from './cnrs/cgu';
import { match } from './cnrs/match';
import { document } from './cnrs/document';
import { subCategory } from './cnrs/subCategory';
import { documentFile } from './cnrs/documentFile';
import { documentsCompanies } from './cnrs/documentsCompanies';
import { tag } from './cnrs/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '4rr0tdosatvzb70almrhxtkbk',
  locale: 'fr',
  name: 'cnrs',
  dashboard: false,
  theme: {
    primary: '#2E35CF',
    secondary: '#B3C3DF',
    info: '#f8dddd',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    invitation,
    candidate,
    candidacy,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
  ],
};

export default config;
